<style>
    .el-dialog {

        width: 900px;
    }

    .el-dialog__wrapper {


    }
</style>
<template>
    <mhospital style="margin-bottom:10px" ref="main0" title="患者" table_name="user" table_show_header>
        <!-- 弹出编辑模态窗口 -->
        <template #writeForm="{writeForm}" style="margin: 0 !important;">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="中文姓名" prop="name" :required="true">
                        <el-input v-model="writeForm.name" style="width: 180px;"/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="手机号" prop="mobile" :required="true">
                        <el-input v-model="writeForm.mobile" minlength="11" maxlength="11" style="width: 180px;"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="性别" prop="sex" :required="true">
                        <el-radio-group v-model="writeForm.sex">
                            <el-radio-button label="1">男</el-radio-button>
                            <el-radio-button label="2">女</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="身高" prop="height">
                        <el-input v-model="writeForm.height" placeholder="单位cm" style="width: 180px;"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="体重" prop="weight">
                        <el-input v-model="writeForm.weight" placeholder="单位kg" style="width: 180px;"/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="birth" label="出生日期" :required="true">
                        <el-date-picker value-format="yyyy-MM-dd" clearable v-model="writeForm.birth" type="date"
                                        placeholder="请输入yyyy-mm-dd" style="width: 180px;"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <!----->
            <!--  <el-row>
                  <el-col :span="12">
                      <el-form-item label="HIS病历号" prop="HIS_PATIENT_ID">
                          <el-input v-model="writeForm.HIS_PATIENT_ID" style="width: 180px;"/>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="英文名称" prop="NAME_PHONETIC">
                          <el-input v-model="writeForm.NAME_PHONETIC" style="width: 180px;"/>
                      </el-form-item>
                  </el-col>
              </el-row>
              <el-row>
                  <el-col :span="12">
                      <el-form-item label="医保卡号" prop="INSURANCE_CARD_NO">
                          <el-input v-model="writeForm.INSURANCE_CARD_NO" style="width: 180px;"/>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="人群分类" prop="CROWD_TYPE">
                          <el-radio-group v-model="writeForm.CROWD_TYPE">
                              <el-radio-button label="普通人">普通人</el-radio-button>
                              <el-radio-button label="军人">军人</el-radio-button>
                              <el-radio-button label="VIP">VIP</el-radio-button>
                          </el-radio-group>
                      </el-form-item>
                  </el-col>
              </el-row>
              <el-row>
                  <el-col :span="12">
                      <el-form-item label="家庭住址" prop="HOME_ADDRESS">
                          <el-input v-model="writeForm.HOME_ADDRESS" style="width: 180px;"/>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="身份证号" prop="id_card">
                          <el-input v-model="writeForm.id_card" style="width: 180px;"/>
                      </el-form-item>
                  </el-col>
              </el-row>
              <el-row>
                  <el-col :span="12">
                      <el-form-item label="数据来源" prop="FROM_TYPE">
                          <el-radio-group v-model="writeForm.FROM_TYPE">
                              <el-radio-button label="新建">新建</el-radio-button>
                              <el-radio-button label="HIS">HIS</el-radio-button>
                          </el-radio-group>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="籍贯" prop="NATIVE_PLACE">
                          <el-input v-model="writeForm.NATIVE_PLACE" style="width: 180px;"/>
                      </el-form-item>
                  </el-col>
              </el-row>
              <el-row>
                  <el-col :span="12">
                      <el-form-item label="邮编" prop="ZIP_CODE">
                          <el-input v-model="writeForm.ZIP_CODE" style="width: 180px;"/>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="民族" prop="NATION">
                          <el-input v-model="writeForm.NATION" style="width: 180px;"/>
                      </el-form-item>
                  </el-col>
              </el-row>
              <el-row>
                  <el-col :span="12">
                      <el-form-item label="通讯地址" prop="MAILING_ADDRESS">
                          <el-input v-model="writeForm.MAILING_ADDRESS" style="width: 180px;"/>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="申请号" prop="ACCESSIONNUMBER">
                          <el-input v-model="writeForm.ACCESSIONNUMBER" style="width: 180px;"/>
                      </el-form-item>
                  </el-col>
              </el-row>-->
            <el-row>
                <el-col :span="12">
                    <el-form-item label="来源" prop="PATIENT_SOURCE">
                        <el-radio-group v-model="writeForm.PATIENT_SOURCE">
                            <el-radio-button label="门诊">门诊</el-radio-button>
                            <el-radio-button label="住院">住院</el-radio-button>
                            <el-radio-button label="体检">体检</el-radio-button>
                            <el-radio-button label="急诊">急诊</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="检查年龄" prop="EXAM_AGE">
                        <el-input v-model="writeForm.EXAM_AGE" style="width: 180px;"/>
                    </el-form-item>
                </el-col>
                <!--  <el-col :span="12">
                      <el-form-item label="门诊号" prop="OUTP_NO">
                          <el-input v-model="writeForm.OUTP_NO" style="width: 180px;"/>
                      </el-form-item>
                  </el-col>-->
            </el-row>
            <!--    <el-row>
                    <el-col :span="12">
                        <el-form-item label="住院号" prop="INP_NO">
                            <el-input v-model="writeForm.INP_NO" style="width: 180px;"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="住院次数" prop="VISIT_NO">
                            <el-input v-model="writeForm.VISIT_NO" style="width: 180px;"/>
                        </el-form-item>
                    </el-col>
                </el-row>-->
            <el-row>
                <!-- <el-col :span="12">
                     <el-form-item label="检查年龄" prop="EXAM_AGE">
                         <el-input v-model="writeForm.EXAM_AGE" style="width: 180px;"/>
                     </el-form-item>
                 </el-col>-->
                <!--  <el-col :span="12">
                      <el-form-item label="年龄单位" prop="AGE_UNIT">
                          <el-input v-model="writeForm.AGE_UNIT" style="width: 180px;"/>
                      </el-form-item>
                  </el-col>-->
            </el-row>
            <!-- <el-row>
                 <el-col :span="12">
                     <el-form-item label="申请时间" prop="REQ_DATETIME">
                         <el-input v-model="writeForm.REQ_DATETIME" style="width: 180px;"/>
                     </el-form-item>
                 </el-col>
                 <el-col :span="12">
                     <el-form-item label="费用" prop="CHARGES">
                         <el-input v-model="writeForm.CHARGES" style="width: 180px;"/>
                     </el-form-item>
                 </el-col>
             </el-row>-->
            <!--      <el-row>
                      <el-col :span="12">
                          <el-form-item label="床号" prop="BED_NO">
                              <el-input v-model="writeForm.BED_NO" style="width: 180px;"/>
                          </el-form-item>
                      </el-col>
                      <el-col :span="12">
                          <el-form-item label="房号" prop="ROOM_NO">
                              <el-input v-model="writeForm.ROOM_NO" style="width: 180px;"/>
                          </el-form-item>
                      </el-col>
                  </el-row>-->
            <!--  <el-row>
                  <el-col :span="12">
                      <el-form-item label="医保性质" prop="CHARGE_TYPE">
                          <el-radio-group v-model="writeForm.CHARGE_TYPE">
                              <el-radio-button label="自费">自费</el-radio-button>
                              <el-radio-button label="医保">医保</el-radio-button>
                          </el-radio-group>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="检查类型,动态血压" prop="EXAM_CLASS">
                          <el-radio-group v-model="writeForm.EXAM_CLASS">
                              <el-radio-button label="BloodPressure">BloodPressure</el-radio-button>
                          </el-radio-group>
                      </el-form-item>
                  </el-col>
              </el-row>
              <el-row>
                  <el-col :span="12">
                      <el-form-item label="临床诊断" prop="CLIN_DIAG">
                          <el-input v-model="writeForm.CLIN_DIAG" style="width: 180px;"/>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="卡号" prop="CARD_NO">
                          <el-input v-model="writeForm.CARD_NO" style="width: 180px;"/>
                      </el-form-item>
                  </el-col>
              </el-row>
              <el-row>
                  <el-col :span="24">
                      <el-form-item label="病史" prop="CASE_HISTORY">
                          <el-input v-model="writeForm.CASE_HISTORY" style="width: 180px;"/>
                      </el-form-item>
                  </el-col>
              </el-row>-->
            <el-row>
                <el-col :span="24">
                    <el-form-item label="备注" prop="CASE_HISTORY">
                        <el-input v-model="writeForm.CASE_HISTORY" style="width: 400px;"/>
                    </el-form-item>
                </el-col>
            </el-row>


        </template>
        <!-- 搜索框 -->
        <template #searchForm="{searchForm}">
            <el-form-item label="姓名" prop="name">
                <el-input v-model="searchForm.name" clearable/>
            </el-form-item>
            <el-form-item label="手机号" prop="mobile">
                <el-input v-model="searchForm.mobile" clearable/>
            </el-form-item>
            <el-form-item label="性别" prop="sex">
                <el-radio-group v-model="searchForm.sex">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="0">未填写</el-radio-button>
                    <el-radio-button label="1">男</el-radio-button>
                    <el-radio-button label="2">女</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item prop="birth" label="年龄范围">
                <el-date-picker value-format="yyyy-MM-dd" v-model="searchForm.birth" type="daterange"
                                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"/>
            </el-form-item>
            <el-form-item prop="icase" label="说明">
                <el-input v-model="searchForm.icase" clearable/>
            </el-form-item>
            <el-form-item prop="create_time" label="注册时间">
                <el-date-picker value-format="yyyy-MM-dd" v-model="searchForm.create_time" type="daterange"
                                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"/>
            </el-form-item>
            <el-form-item v-if="hrole==24||hrole==23" prop="hospital_id" label="社区医院">
                <el-select v-model="searchForm.hospital_id" placeholder="请选择" clearable filterable
                           size="mini"
                           @change="search_btn('main0')">
                    <el-option value="" label="全部"/>
                    <el-option v-for="item in hospital_opt" :key="item.id" :label="item.name"
                               :value="item.id"/>
                </el-select>
            </el-form-item>
        </template>
        <!-- 列表 -->
        <template #table>
            <!-- <el-table-column label="基本信息"> -->

            <el-table-column label="姓名" align="center" width="120">
                <template slot-scope="scope">
                    <el-link type="primary" @click="goto_report_by_uid(scope.row.id)">{{scope.row.name}}</el-link>
                </template>
            </el-table-column>
            <el-table-column  label="user_id" prop="id" align="center"/>
            <el-table-column v-if="hrole==24||hrole==23" label="社区医院" prop="hospital.name" align="center">

            </el-table-column>
            <el-table-column label="手机号" prop="mobile" align="center"/>
            <el-table-column label="性别" prop="sex" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.sex==1">男</span>
                    <span v-else>女</span>
                </template>·
            </el-table-column>
            <el-table-column label="出生日期" prop="birth" align="center"/>
            <el-table-column label="身高" prop="height" align="center"/>
            <el-table-column label="体重" prop="weight" align="center"/>
            <el-table-column label="注册时间" prop="create_time" align="center" width="160"/>
            <el-table-column label="绑定设备" align="center">
                <template slot-scope="scope">
					<span v-if="scope.row.gprs&&scope.row.gprs.id>0">
<!--						{{scope.row.gprs.imei}}-->
                        	<el-tag type="success" size="mini">已绑定</el-tag>
					</span>
                    <span v-else>
						<el-tag type="danger" size="mini">未绑定</el-tag>
					</span>
                </template>
            </el-table-column>

            <!-- </el-table-column> -->
            <el-table-column label="操作" width="160" align="center">
                <template slot-scope=" scope">
                    <el-link style="margin-right:10px" icon="el-icon-edit" type="primary"
                             @click="edit_btn('main0', scope.row.id)">编辑
                    </el-link>
                    <el-link icon="el-icon-delete" type="primary" @click="del_btn('main0', scope.row.id)">删除</el-link>
                </template>
            </el-table-column>
        </template>
    </mhospital>

</template>
<script>
    import mhospital from "../../components/mhospital.vue";
    import request from "../../plugins/axios";

    export default {
        data() {
            return {
                uid_opt: [],
                hrole: '',
                hospital_opt: [],
            };
        },
        components: {
            mhospital,
        },
        mounted() {
            request({
                url: "/hospital/Read/jk_hospital_opt",
                data: {
                    hp: 2000
                },
            }).then((ret) => {
                if (ret.code == 1) {
                    this.hospital_opt = ret.data;
                }
            });
            this.hrole = localStorage.getItem('hrole')
        },
        methods: {
            search_btn(ref_name) {
                this.$refs[ref_name].onSearch();
            },
            del_btn(ref_name, id) {
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$refs[ref_name].onDelete(id);
                })
            },
            edit_btn(ref_name, id) {
                this.$refs[ref_name].showWriteForm(id);
            },
            addsub_btn(ref_name, send_id) {
                this.$refs[ref_name].onAddsub({
                    id: 0,
                    buy_id: send_id,
                    goods_type: "",
                    goods_num: "",
                    client_id: "",
                    note: "",
                });
            },
            deletesub_btn(ref_name, index) {
                this.$refs[ref_name].onDeletesub(index);
            },
            go_tab(activeName, oldActiveName) {
                // console.log(activeName);
                // if (activeName == "1") {
                // this.$refs["main2"].writeForm.role = 4;
                // this.$refs["main2"].searchForm.role = 4;
                // } else if (activeName == "0") {
                // this.$refs["main0"].writeForm.role = "";
                // this.$refs["main0"].searchForm.role = "";
                // }
            },
            goto_report_by_uid(uid) {
                this.$router.push({name: "/admin/report", params: {uid: uid}});
            },
            look_report(id) {
                request({
                    url: "/hospital/Read/make_docx_v2",
                    data: {
                        id,
                    },
                }).then((ret) => {
                    if (ret.code == 1) {
                        window.open(ret.data);
                    }
                });
            },
            mindexMethod(index) {
                return this.padNumber(index, 5, 0);
            },
            padNumber(num, fill) {
                let len = ("" + num).length;
                if (fill > len) {
                    // 新建一个空数组，长度为所缺位数+1，利用join(0)，得到一个000...的字符串
                    num = Array(fill - len + 1 || 0).join(0) + num;
                }
                return num;
            },
        },
    };
</script>
